import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

export type Currency = 'EUR' | 'USD' | 'RUB';

type ExchangeRates = {
  [key in Currency]: {
    [key in Currency]?: number;
  };
};

interface CurrencyContextType {
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  convertPrice: (price: number, fromCurrency: Currency, toCurrency: Currency) => number;
  formatPrice: (price: number, currency: Currency) => string;
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(undefined);

export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currency, setCurrency] = useState<Currency>('USD');
  const [exchangeRates, setExchangeRates] = useState<ExchangeRates | null>(null);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch('/api/convert-currency');
        const data = await response.json();
        
        if (data.rates) {
          // Создаем матрицу конвертации для всех валют
          const rates: ExchangeRates = {
            USD: { EUR: data.rates.EUR, RUB: data.rates.RUB, USD: 1 },
            EUR: { 
              USD: 1 / data.rates.EUR,
              RUB: data.rates.RUB / data.rates.EUR,
              EUR: 1
            },
            RUB: {
              USD: 1 / data.rates.RUB,
              EUR: data.rates.EUR / data.rates.RUB,
              RUB: 1
            }
          };

          console.log('Loaded exchange rates:', rates);
          setExchangeRates(rates);
        }
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);

  const convertPrice = useCallback((price: number, fromCurrency: Currency, toCurrency: Currency): number => {
    if (fromCurrency === toCurrency) return price;
    if (!exchangeRates) {
      console.warn('Exchange rates not loaded yet');
      return price; // Возвращаем исходную цену, если курсы еще не загружены
    }

    const rate = exchangeRates[fromCurrency]?.[toCurrency];
    if (typeof rate === 'undefined') {
      console.error(`Exchange rate not found for ${fromCurrency} to ${toCurrency}`);
      return price;
    }
    
    const converted = price * rate;
    console.log(`Converting ${price} ${fromCurrency} to ${toCurrency}: ${converted} (rate: ${rate})`);
    return converted;
  }, [exchangeRates]);

  const formatPrice = useCallback((price: number, currency: Currency): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(price);
  }, []);

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertPrice, formatPrice }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};
