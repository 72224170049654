import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { ReactComponent as WhiteCatIcon } from '../images/whitecat_stroke.svg';
import { useCurrency, Currency } from '../contexts/CurrencyContext';
import LanguageIcon from '@mui/icons-material/Language';
import styled from '@emotion/styled';
import { logEvent, AmplitudeEvents } from '../utils/amplitude';

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: '"Nunito", sans-serif',
  fontWeight: 800,
  color: 'white',
  textDecoration: 'none',
  marginRight: '10px',
  position: 'relative',
  zIndex: 1,
  transition: 'all 0.3s ease',
  '&:hover': {
    '& img': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.7))',
    },
    '& span': {
      textShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
      transform: 'scale(1.05)',
    }
  }
}));

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: { auth: { user: any } }) => state.auth.user !== null);
  const { currency, setCurrency } = useCurrency();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrencyChange = (newCurrency: Currency) => {
    logEvent(AmplitudeEvents.CURRENCY_CHANGE, {
      from: currency,
      to: newCurrency
    });
    setCurrency(newCurrency);
    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const getCurrencySymbol = (curr: Currency) => {
    switch (curr) {
      case 'EUR': return '€';
      case 'USD': return '$';
      case 'RUB': return '₽';
      default: return curr;
    }
  };

  const handleLangMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setLangAnchorEl(null);
  };

  const handleLanguageChange = (lang: string) => {
    logEvent(AmplitudeEvents.LANGUAGE_CHANGE, {
      from: i18n.language,
      to: lang
    });
    i18n.changeLanguage(lang);
    handleLangMenuClose();
  };

  const getCountryFlagEmoji = (countryCode: string) => {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  };

  const getCurrentFlag = () => {
    switch (i18n.language) {
      case 'ru': return getCountryFlagEmoji('RU');
      case 'en': return getCountryFlagEmoji('GB');
      case 'de': return getCountryFlagEmoji('DE');
      case 'fr': return getCountryFlagEmoji('FR');
      case 'es': return getCountryFlagEmoji('ES');
      default: return getCountryFlagEmoji('GB');
    }
  };

  return (
    <AppBar 
      position="static" 
      elevation={0}
      sx={{ 
        backgroundColor: 'transparent',
        borderRadius: '0 0 40px 40px',
      }}
    >
      <Toolbar 
        disableGutters
        sx={{ 
          background: 'linear-gradient(45deg, rgba(0, 113, 226, 0.85), rgba(0, 166, 255, 0.70))',
          backdropFilter: 'blur(8px)',
          borderRadius: '0 0 40px 40px',
          position: 'relative',
          paddingLeft: '24px',
          paddingRight: '24px',
          boxShadow: '0 4px 20px rgba(100, 149, 237, 0.6)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '0 0 40px 40px',
            backdropFilter: 'blur(5px)',
            zIndex: 0,
          },
          '& > *': {
            position: 'relative',
            zIndex: 1
          }
        }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center',
            position: 'relative',
            zIndex: 1
          }}
        >
          <StyledRouterLink to="/">
            <WhiteCatIcon 
              style={{ 
                height: '47px', 
                marginRight: '10px', 
                marginLeft: '5%',
                position: 'relative',
                zIndex: 1,
                transition: 'all 0.3s ease',
                
                fill: 'white',
                filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0))',
                transform: 'scale(1)',
              }} 
              onMouseEnter={(e) => {
                e.currentTarget.style.filter = 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.7))';
                e.currentTarget.style.transform = 'scale(1.1)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.filter = 'drop-shadow(0 0 8px rgba(255, 255, 255, 0))';
                e.currentTarget.style.transform = 'scale(1)';
              }}
            />
            <span style={{
              transition: 'all 0.3s ease',
              display: 'inline-block'
            }}>
              avia.pet
            </span>
          </StyledRouterLink>
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          marginRight: '-12px',
          position: 'relative',
          zIndex: 1
        }}>
          <IconButton 
            onClick={handleClick}
            sx={{ 
              color: 'white', 
              border: '2px solid white',
              borderRadius: '50%',
              width: '32px',
              height: '32px',
              marginRight: 2,
              fontSize: '1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {getCurrencySymbol(currency)}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '16px',
                mt: 1,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
              },
              '& .MuiMenuItem-root': {
                minWidth: '100px',
                py: 1,
                px: 2,
                '&:hover': {
                  backgroundColor: 'rgba(0, 113, 226, 0.08)'
                },
                '&:first-of-type': {
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px'
                },
                '&:last-of-type': {
                  borderBottomLeftRadius: '16px',
                  borderBottomRightRadius: '16px'
                }
              }
            }}
          >
            <MenuItem onClick={() => handleCurrencyChange('USD')}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1.5,
                width: '100%',
                '& span:first-of-type': {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'primary.main'
                }
              }}>
                <span>$</span>
                <span>USD</span>
              </Box>
            </MenuItem>
            <MenuItem onClick={() => handleCurrencyChange('EUR')}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1.5,
                '& span:first-of-type': {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'primary.main'
                }
              }}>
                <span>€</span>
                <span>EUR</span>
              </Box>
            </MenuItem>
            <MenuItem onClick={() => handleCurrencyChange('RUB')}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1.5,
                '& span:first-of-type': {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'primary.main'
                }
              }}>
                <span>₽</span>
                <span>RUB</span>
              </Box>
            </MenuItem>
          </Menu>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            ml: 2, 
            mr: 1
          }}>
            <IconButton 
              onClick={handleLangMenuOpen}
              sx={{ 
                color: 'white',
                border: '2px solid white',
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                marginRight: 1,
                padding: 0,
                overflow: 'hidden',
                fontSize: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& span': {
                  lineHeight: 1,
                  transform: 'scale(1.6)',
                  display: 'block',
                  position: 'relative',
                  top: '1px'
                }
              }}
            >
              <span>{getCurrentFlag()}</span>
            </IconButton>
            <Menu
              anchorEl={langAnchorEl}
              open={Boolean(langAnchorEl)}
              onClose={handleLangMenuClose}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '16px',
                  mt: 1,
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                },
                '& .MuiMenuItem-root': {
                  minWidth: '120px',
                  py: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 113, 226, 0.08)'
                  },
                  '&:first-of-type': {
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px'
                  },
                  '&:last-of-type': {
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px'
                  }
                }
              }}
            >
              <MenuItem onClick={() => handleLanguageChange('ru')}>
                {getCountryFlagEmoji('RU')} Русский
              </MenuItem>
              <MenuItem onClick={() => handleLanguageChange('en')}>
                {getCountryFlagEmoji('GB')} English
              </MenuItem>
              <MenuItem onClick={() => handleLanguageChange('de')}>
                {getCountryFlagEmoji('DE')} Deutsch
              </MenuItem>
              <MenuItem onClick={() => handleLanguageChange('fr')}>
                {getCountryFlagEmoji('FR')} Français
              </MenuItem>
              <MenuItem onClick={() => handleLanguageChange('es')}>
                {getCountryFlagEmoji('ES')} Español
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
